import { Edition, Property } from 'src/app/models/dwrm/base';
import { Container } from 'src/app/models/dwrm/container';
import { Upload } from 'src/app/models/dwrm/upload';
import { NotificationOriginName } from 'src/app/models/notification';
import { BaseModel, PaginatedResponse } from 'src/app/models/utils';

export const ORDER_TYPES = [
  'ABHOLUNG',
  'TAUSCH',
  'ENTLEERUNG',
  'AUFSTELLUNG',
] as const;

export enum OrderTypes {
  ABHOLUNG = 'Abholung',
  TAUSCH = 'Tausch',
  ENTLEERUNG = 'Entleerung',
  AUFSTELLUNG = 'Aufstellung',
}

export type OrderType = keyof typeof OrderTypes;

export const ORDER_STATUSES = [
  'ENTWURF',
  'BEAUFTRAGT',
  'DISPONIERT',
  'UMDISPONIERT',
  'AVISIERT',
  'ABGEHOLT',
  'ENTLADEN',
  'VERRECHNET',
  'STORNIERT',
  'REKLAMIERT',
] as const;

export enum OrderStatuses {
  ENTWURF = 'Entwurf',
  BEAUFTRAGT = 'Beauftragt',
  DISPONIERT = 'Disponiert',
  UMDISPONIERT = 'Umdisponiert',
  AVISIERT = 'Avisiert',
  ABGEHOLT = 'Abgeholt',
  ENTLADEN = 'Entladen',
  VERRECHNET = 'Verrechnet',
  STORNIERT = 'Storniert',
  REKLAMIERT = 'Reklamiert',
}

export const ORDER_STATUSES_COLORS = {
  ENTWURF: '#f5f5f5',
  BEAUFTRAGT: '#bbdefb',
  DISPONIERT: '#fff9c4',
  UMDISPONIERT: '#fff9c4',
  AVISIERT: '#ffe0b2',
  ABGEHOLT: '#b2dfdb',
  ENTLADEN: '#b2dfdb',
  VERRECHNET: '#c8e6c9',
  STORNIERT: '#ef9a9a',
  REKLAMIERT: '#ef9a9a',
};

export type OrderStatus = keyof typeof OrderStatuses;

export interface OrderRequestData {
  id?: string;
  userId: string;
  companyId: string;
  disposerUserId?: string;
  disposerCompanyId: string;
  contractId?: string;
  wasteStreamId: string;
  containerId: string;
  locationId: string;
  properties: Property[];
  type: OrderType;
  status: OrderStatus;
  ewc: string;
  aut: string;
  quantity: number;
  collectionDate: string;
  timeFrom: string;
  timeTo: string;
  additional?: string;
  comment?: string;
  payload?: {
    origin: NotificationOriginName;
    [key: string]: any;
  };
}

export interface OrderContractRequestData {
  id?: string;
  disposerUserId: string;
  companyId: string;
  disposerCompanyId: string;
  contractId?: string;
  locationId: string;
}

export interface OrderActionRequestData {
  action: OrderStatus;
  collectionDate?: string;
  timeFrom?: string;
  timeTo?: string;
  vehicleDriver?: string;
  vehiclePlate?: string;
  statusData?: string;
  updatedByDisposer?: boolean;
  payload?: { [key: string]: any };
}

export interface OrderStatusUpdate {
  status: OrderStatus;
  timestamp: string;
  data: string;
}

export interface Order extends Partial<BaseModel> {
  userId: string;
  companyId: string;
  disposerUserId: string;
  disposerCompanyId: string;
  contractId?: string;
  wasteStreamId: string;
  containerId: string;
  locationId: string;
  uploadId: string;
  container: Container | null;
  upload: Upload | null;
  properties: Property[];
  editions: Edition[];
  statusUpdates: OrderStatusUpdate[];
  type: OrderType;
  status: OrderStatus;
  ewc: string;
  aut: string;
  number: number;
  quantity: number;
  collectionDate: string;
  timeFrom: string;
  timeTo: string;
  additional: string;
  origin: string;
  vehicleDriver: string;
  vehiclePlate: string;
}

export type PaginatedOrders = PaginatedResponse<Order[]>;

export interface LastOrderPreview {
  containerId: string;
  orderId: string;
  status: OrderStatus;
  created: string;
  updated?: string;
}

export const HOURS = [
  { value: '08', label: '08:00' },
  { value: '09', label: '09:00' },
  { value: '10', label: '10:00' },
  { value: '11', label: '11:00' },
  { value: '12', label: '12:00' },
  { value: '13', label: '13:00' },
  { value: '14', label: '14:00' },
  { value: '15', label: '15:00' },
  { value: '16', label: '16:00' },
  { value: '17', label: '17:00' },
  { value: '18', label: '18:00' },
  { value: '19', label: '19:00' },
  { value: '20', label: '20:00' },
  { value: '21', label: '21:00' },
  { value: '22', label: '22:00' },
] as const;

export const HOURS_MAP = {
  '08': '08:00',
  '09': '09:00',
  '10': '10:00',
  '11': '11:00',
  '12': '12:00',
  '13': '13:00',
  '14': '14:00',
  '15': '15:00',
  '16': '16:00',
  '17': '17:00',
  '18': '18:00',
  '19': '19:00',
  '20': '20:00',
  '21': '21:00',
  '22': '22:00',
};
